import * as React from 'react'
import { useState } from 'react'

import styled, { css } from 'styled-components'

import Layout from 'layouts/main'

// COMPONENTS
import { Button, SecondaryButton } from 'components/styled/button'
import { Input } from 'components/styled/input'
import { Textarea } from 'components/styled/textarea'
import Dropdown from 'components/styled/dropdown'

import { SCREEN } from 'styles/screens'
import { getOptions } from '../data/dictionary'
import axios from 'axios'
import { config } from '../config'
import { cloneDeep } from 'lodash'
import queryString from 'query-string'

const Container = styled.div`
  min-height: 100vh;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1520px;

  textarea {
    height: calc(var(--spacing) * 36);
  }

  .mb-40px {
    margin-bottom: 40px;
  }

  a {
    color: #0656F1;
  }

  .mb-3rem {
    margin-bottom: 3rem;
  }

  .d-block {
    display: block;
  }

  button {
    font-size: 20px;
    padding: 15px 40px;
  }
`

const Row = styled.div`
  flex-grow: 1;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1520px;

  .col-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  &.mb-100px {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .row {
      flex-direction: column;

      .col-6 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    margin-bottom: 40px;
    &.mb-100px {
      margin-bottom: 60px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 4px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 20px;
    flex-direction: column;
    &.mb-100px {
      margin-bottom: 40px;
    }
  }

  input, .row, textarea {
    margin-bottom: 20px;
  }
`
const ColumnFive = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-right: 15px;
  padding-left: 15px;

  &.ml-auto {
    margin-left: auto;
  }

  &.mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 14px;
      font-weight: normal;
    }
  }

  div.px-0 {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    &.ml-auto {
      margin-left: 0;
    }

    &.mx-auto {
      margin-left: 0;
      margin-right: 0;
    }
  }
`
const Column = styled.div`
  flex: 0 0 auto;
  width: 91.66666667%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

  h5 {
    margin-bottom: 0;
  }

  h6.title {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 40px;

    img {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }

  p {
    margin-bottom: 60px;
    margin-top: 10px;
  }

  button {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const ColumnOne = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  display: flex;
  align-items: center;

  a {
    margin-top: 20px;
    font-size: 16px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    a {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: end;
    margin-bottom: 0;
    a {
      margin-top: 0;
    }
`

const UploadVideo = styled.div`
  border: var(--border-size) solid var(--primary-light);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing) * 12);
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p, button, h6 {
    font-size: 18px;
  }

  p {
    margin-bottom: 40px;
  }

  button {
    margin-bottom: 15px;
  }
`

const Hr = styled.div`
  height: 0;
  border-top: 1px solid var(--primary-light);
  margin: calc(var(--spacing) * 9) 0;
`
const DEFAULT_FORM_DATA = {
  title: '',
  steps: [{ step: '', pain: null }],
  category: '',
  target_model_1: '',
  target_model_2: '',
  stakeholders: '',
  problem_summary: '',
  problem_nature: '',
  problem_location: '',
  video_url: '',
  video_embed_url: '',
}

const FormTemplate = () => {

  const [data, setData] = useState(cloneDeep(DEFAULT_FORM_DATA))
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [showEmbedInput, setShowEmbedInput] = useState(false)
  const [showOptionalSteps, setShowOptionalSteps] = useState(false)

  React.useEffect(async () => {
    const token = window.localStorage.getItem('token')
    try {

      await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)


    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/'
    }
    try {
      const { id } = queryString.parse(window.location.search)

      if (id) {
        let response = (await axios.get(`${config.API_BASE_URL}/challenges/${id}?token=${token}`)).data
        setData(response.data)
        setShowOptionalSteps(true);
        if (response.data.video_embed_url) {
          setShowEmbedInput(true)
        }
      }
    } catch (e) {

    }
  }, [])

  const onFileChange = (value) => {
    setFile(value)
    setShowEmbedInput(false)
    let d = cloneDeep(data)
    d.video_embed_url = null
    setData(d)
  }

  const onChange = (key, value) => {
    let d = { ...data }
    d[key] = value
    setData(d)
  }

  const onChangeStep = (index, value) => {
    let d = { ...data }
    d.steps[index] = value
    setData(d)
  }

  const validate = () => {
    let _errors = {}
    let required = ['title', 'category', 'target_model_1', 'stakeholders', 'problem_summary']
    required.forEach(r => {
      if (!data[r]) {
        _errors[r] = 'This field is required'
      }
    })
    setErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const resetFile = () => {
    setFile(null)
    document.getElementById('selectImage').value = null
  }
  const reset = () => {
    setData(cloneDeep(DEFAULT_FORM_DATA))
    setShowEmbedInput(false)
    setShowOptionalSteps(false)
    resetFile()
  }

  const submit = async () => {
    if (!validate()) {
      return
    }
    setLoading(true)

    if (file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = async () => {
        try {
          const image = reader.result
          let { url } = (await axios.post(`${config.API_BASE_URL}/programs/files/upload/video`, { image })).data
          let _data = cloneDeep(data)
          _data.video_url = url
          await createChallange(_data)
        } catch (e) {
          alert('Error uploading video')
        }
      }
    } else {
      await createChallange(data)
    }
  }

  const createChallange = async (_data) => {
    const token = window.localStorage.getItem('token')
    const { id } = queryString.parse(window.location.search)


    try {
      let challenge = (await axios.post(`${config.API_BASE_URL}/challenges/submit`, {
        challenge: { data: _data, id },
        token,
      })).data
      reset()
      alert('Challenge submitted')
    } catch (e) {
      alert('An error has occurred')
    }
    setLoading(false)
  }

  const onClickImage = () => {
    document.getElementById('selectImage').click()
  }


  return (
    <Layout title={`BuildUp: Form`} hideTitlePrefix={true}>
      <input id='selectImage' accept='video/*' capture='camcorder' onChange={e => (onFileChange(e.target.files[0]))}
             hidden type='file' />
      <Container fluid>
        <Row>
          <Column>
            <h5>Challenge submission form</h5>
            <p>This submission will be considered for a challenge in the BuildUp bootcamp.</p>
          </Column>
          <ColumnFive className='mx-auto'>
            <Input error={errors.title} onChange={e => {
              onChange('title', e.target.value)
            }} value={data.title} label='Title'
                   tooltiptext=' Make the title concise and enticing. Write a sentence case reflecting the main idea of the problem without a solution clue.'
                   appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                   type='text' />
            <Dropdown
              error={errors.category}
              label='Category'
              tooltiptext='Choose the field to which the problem is mainly related.'
              appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
              options={getOptions('industry')}
              onSelect={v => {
                onChange('category', v)
              }}
              value={data.category}
              containerCss={css`
                min-width: 220px;
                margin-bottom: 20px;
              `}
            />
            <Row className='row'>
              <ColumnFive className='px-0 col-6'>
                <Dropdown
                  error={errors.target_model_1}
                  label='Target Model'
                  tooltiptext='Choose one or two models best describing potential users of the solution.'
                  appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                  options={getOptions('target_models')}
                  onSelect={v => {
                    onChange('target_model_1', v)
                  }}
                  value={data.target_model_1}
                  containerCss={css`
                    min-width: 220px;
                  `}
                />
              </ColumnFive>
              <ColumnFive className='px-0 col-6'>
                <Dropdown
                  value={data.target_model_2}
                  label='and'
                  options={getOptions('target_models')}
                  onSelect={v => {
                    onChange('target_model_2', v)
                  }}
                  containerCss={css`
                    min-width: 220px;
                  `}
                />
              </ColumnFive>
            </Row>
            <Input error={errors.stakeholders}
                   value={data.stakeholders}
                   onChange={e => onChange('stakeholders', e.target.value)} label='Key Stakeholders'
                   tooltiptext=' Indicate the key stakeholders that are directly involved in a pain point case.'
                   appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                   type='text' />
            <Textarea
              error={errors.problem_summary}
              value={data.problem_summary}
              onChange={e => onChange('problem_summary', e.target.value)}
              placeholder='The summary of the high-level problem is that... '
              label='High-Level Problem Summary'
            />
          </ColumnFive>
          <ColumnFive className='mx-auto'>
            <h6 className='title'>Submissions Guidelines</h6>
            <ul>
              <li>
                Write an opening sentence that stimulates interest as well as conveys an issue.
              </li>
              <li>
                Indicate why the problem is important
              </li>
              <li>
                Formulate the problem statement into one sentence.
              </li>
              <li>
                Do not write the problem statement in the form of a question.
              </li>
            </ul>
            {/*<a href='#' className='mb-3rem d-block'>Read More </a>*/}
            <UploadVideo>
              <h6>Include a video with your proposal</h6>
              <p>Videos help give personality to your project proposal.</p>
              <Button onClick={onClickImage}>Upload</Button>
              {file && <>

                {file.name}
                <a href='#' onClick={e => {
                  e.preventDefault()
                  resetFile()
                }}>x</a>
              </>
              }
              <SecondaryButton onClick={e => {
                resetFile()
                setShowEmbedInput(true)
              }}>Embed</SecondaryButton>
              {showEmbedInput &&
              <>
                <Input error={errors.video_embed_url}
                       value={data.video_embed_url}
                       onChange={e => onChange('video_embed_url', e.target.value)} label='Embed URL'
                       tooltiptext='Videos help give personality to your project proposal.'
                       appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                       type='text' />
                <a href='#' onClick={e => {
                  e.preventDefault()
                  onChange('video_embed', null)
                  setShowEmbedInput(false)
                }}>x</a>
              </>
              }
            </UploadVideo>

          </ColumnFive>
        </Row>

        <Row>
          <Column>
            <Hr />
          </Column>
          <Column>
            <h5>Optional questions</h5>
            <p>
              Completing these additional questions can improve your proposal chances of acceptance by 85%.
            </p>
          </Column>
        </Row>

        {showOptionalSteps ?
          <>
            <Row>
              <ColumnFive className='mx-auto'>
                <Textarea
                  tooltipPosition='right'
                  value={data.problem_nature}
                  onChange={e => onChange('problem_nature', e.target.value)}
                  tooltiptext='Indicate if this is a technical, operational, or any other type of problem and follow with an explanatory sentence.'
                  appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                  label='What is the nature of the problem?'
                />
              </ColumnFive>
              <ColumnFive className='mx-auto'>
                <Textarea
                  value={data.problem_location}
                  onChange={e => onChange('problem_location', e.target.value)}
                  tooltiptext='Indicate the physical or virtual facilities, systems, or processes where the problem occurs. Make it narrow enough to be manageable.'
                  appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                  label='Problem location'
                />
              </ColumnFive>
            </Row>
            <Row>
              <Column>
                <h6 className='title'>Steps in the process
                  <div className='tooltip' data-position='right'
                       data-tooltip='Briefly describe steps that are preceding and following a problem to have a better understanding of the process chain where the problem occurs.'>
                    <img
                      src='https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'
                      alt='Icon'
                      layout='constrained'
                      placeholder='tracedSVG'
                    />
                  </div>
                </h6>
              </Column>
            </Row>
            {data.steps.map((step, index) => {
              const hasPain = step.pain !== null && typeof step.pain !== 'undefined'
              return <Row>
                <ColumnFive className='ml-58px'>
                  <Input
                    value={step.step}
                    onChange={e => onChangeStep(index, { ...step, step: e.target.value })} label={`STEP ${index + 1}`}
                    type='text' />
                </ColumnFive>

                <ColumnOne>{!hasPain ? <a href='#' onClick={(e) => {
                  e.preventDefault()
                  onChangeStep(index, { ...step, pain: '' })
                }}>+Pain</a> : <a href='#' onClick={(e) => {
                  e.preventDefault()
                  onChangeStep(index, { ...step, pain: null })
                }}>Delete Pain</a>}</ColumnOne>
                {hasPain &&
                <ColumnFive className='ml-1'>
                  <Input value={step.pain}
                         onChange={e => onChangeStep(index, { ...step, pain: e.target.value })}
                         label={`STEP ${index + 1} Pain`} type='text' />
                </ColumnFive>
                }
              </Row>
            })}
            <Row className='mb-100px'>
              <Column>
                <a href='#' className='mb-3rem d-block' onClick={(e) => {
                  e.preventDefault()
                  onChange('steps', [...data.steps, { step: '' }])
                }}>+ Add Step</a>
                <Button disabled={loading} onClick={submit}>{loading ? 'Please wait..' : 'Submit'}</Button>
              </Column>
            </Row>
          </>
          :
          <Row>
            <Column>
              <Button disabled={loading} onClick={() => {
                setShowOptionalSteps(true)
              }}>Continue</Button>
            </Column>
          </Row>
        }
      </Container>
    </Layout>
  )
}

export default FormTemplate
